

















































































































































































import { Mode } from "@/models/enums/global.enum";
import { jobCostingPostMeatroomService } from "@service/job-costing-post-meatroom.service";
import {
  RequestJobCostingPostMeatroomUpdate,
  ResponseJobCostingPostMeatroomDetail,
  ResponseJobCostingPostMeatroomPostProductDetail,
} from "@interface/job-costing-post-meatroom.interface";
import Vue from "vue";
import MNotificationVue from "@/mixins/MNotification.vue";
import { IGenericResponsePost } from "@interface/common.interface";
import { POST_MEATROOM_LINE_STATUS } from "@/models/enums/job-costing.enum";
import { FormModel } from "ant-design-vue";
import { printPDF } from "@/helpers/print-js";
import { batchService } from "@/services-v2/batch.service";

interface ITableRow extends ResponseJobCostingPostMeatroomPostProductDetail {
  key: any;
  no: number;
}

export default Vue.extend({
  name: "JobCostingPostMeatroomProcess",
  components: {
    CSelectRack: () =>
      import(
        /*webpackPrefetch: true*/ "@/components/shared/select-rack/CSelectRack.vue"
      ),
    CSelectCondition: () =>
      import(
        /*webpackPrefetch: true*/ "@/components/shared/select-condition/CSelectCondition.vue"
      ),
  },
  mixins: [MNotificationVue],
  data() {
    return {
      POST_MEATROOM_LINE_STATUS,
      form: {
        operatorName: "",
      },
      dtTable: [] as ITableRow[],
      loading: {
        table: false,
        submit: false,
        print: false,
      },
      dtDetailPostMeatroom: {} as ResponseJobCostingPostMeatroomDetail,
      jobCostingId: "",
      rules: {
        operatorName: [
          {
            required: true,
            trigger: "blur",
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
      },
      selectedRow: [] as string[],
    };
  },
  computed: {
    isModeCreate(): boolean {
      return this.$route.meta.mode === Mode.CREATE;
    },
    disableSubmit(): boolean {
      return this.dtTable.every(
        (value) => value.status === POST_MEATROOM_LINE_STATUS.PROCESSED
      );
    },
    rowSelection() {
      return {
        selectedRow: this.selectedRow,
        onChange: this.onRowSelect,
        getCheckboxProps: (record) => ({
          props: {
            disabled: !record.batchNumber,
            batchNumber: record.batchNumber,
          },
        }),
      };
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.jobCostingId = this.$route.params.id;
      this.getDetail(this.jobCostingId);
    }
  },
  methods: {
    getDetailPostMeatroom(
      id: string
    ): Promise<ResponseJobCostingPostMeatroomDetail> {
      return jobCostingPostMeatroomService.getDetail(id);
    },
    updatePostMeatroom(
      id: string,
      payload: RequestJobCostingPostMeatroomUpdate
    ): Promise<IGenericResponsePost> {
      return jobCostingPostMeatroomService.updateJobCosting(id, payload);
    },
    handleBack(): void {
      this.$router.push({ name: "sales.transactionsales.jobcosting" });
    },
    async getDetail(id: string): Promise<void> {
      try {
        this.loading.table = true;
        this.dtDetailPostMeatroom = await this.getDetailPostMeatroom(id);
        this.form.operatorName = this.dtDetailPostMeatroom.operatorName;
        this.fillTable();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.table = false;
      }
    },
    fillTable(): void {
      this.dtTable = this.dtDetailPostMeatroom.postProduct.map((x, i) => {
        return {
          key: i,
          no: i + 1,
          ...x,
        };
      });
    },
    handleSubmit(): void {
      const $form = this.$refs.formPostMeatroom as FormModel;
      $form.validate((valid: boolean) => {
        if (valid) {
          this.updateData();
        } else {
          this.showNotifValidationError();
        }
      });
    },
    async updateData(): Promise<void> {
      try {
        this.loading.submit = true;
        await this.updatePostMeatroom(
          this.jobCostingId,
          this.createReqUpdate()
        );
        this.showSubmitSuccessMesssage();
        this.getDetail(this.jobCostingId);
      } catch (error) {
        this.showSubmitFailedMesssage();
      } finally {
        this.loading.submit = false;
      }
    },
    async handlePrint(): Promise<void> {
      try {
        if (!this.selectedRow || !this.selectedRow.length) {
          this.showNotifWarning("notif_choose_row");
          return;
        }
        this.loading.print = true;
        const batchNumbers: string = this.selectedRow.join(",");
        const file = await batchService.getPrintFile(batchNumbers);
        const pdf = new Blob([file]);
        printPDF(pdf, () => {
          this.showErrorMessage("error_print_fail");
        });
      } catch (error) {
        console.error(error);
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.print = false;
      }
    },
    createReqUpdate(): RequestJobCostingPostMeatroomUpdate {
      return {
        operatorName: this.form.operatorName,
        postProduct: this.dtTable
          .filter(
            (x) => x.status === POST_MEATROOM_LINE_STATUS.READY_TO_MEATROOM
          )
          .map((x) => {
            return {
              condition: x.condition,
              id: x.id,
              locationId: x.locationId,
            };
          }),
      };
    },
    onRowSelect(
      _,
      items: ResponseJobCostingPostMeatroomPostProductDetail[]
    ): void {
      const batchNumbers = items.map((item) => item.batchNumber);
      this.selectedRow = batchNumbers;
    },
  },
});
