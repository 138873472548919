import { Api } from "@/models/class/api.class";
import { RequestJobCostingPostMeatroomUpdate, ResponseJobCostingPostMeatroomDetail } from "@interface/job-costing-post-meatroom.interface";
import { ErrorResponseModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";
import { IGenericResponsePost } from "@/models/interface-v2/common.interface";

export class JobCostingPostMeatroomServices extends HttpClient {
  constructor() {
    super();
  }

  getDetail(jobCostingId: string): Promise<ResponseJobCostingPostMeatroomDetail> {
    return this.get<ResponseJobCostingPostMeatroomDetail>(`${Api.JobCostingPostMeatroom}/${jobCostingId}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  updateJobCosting(jobCostingId: string, payload: RequestJobCostingPostMeatroomUpdate): Promise<IGenericResponsePost> {
    return this.put<IGenericResponsePost, RequestJobCostingPostMeatroomUpdate>(`${Api.JobCostingPostMeatroom}/${jobCostingId}`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

}

export const jobCostingPostMeatroomService = new JobCostingPostMeatroomServices();
