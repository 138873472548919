var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_post_meatroom_process") } },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-form-model",
                {
                  ref: "formPostMeatroom",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-align": "left"
                  }
                },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_operator_name"),
                        prop: "operatorName"
                      }
                    },
                    [
                      _c("a-input", {
                        staticStyle: { width: "250px" },
                        attrs: {
                          "allow-clear": "",
                          placeholder: _vm.$t("lbl_type_here"),
                          "read-only": ""
                        },
                        model: {
                          value: _vm.form.operatorName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "operatorName", $$v)
                          },
                          expression: "form.operatorName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-table",
                {
                  staticStyle: { "min-height": "400px" },
                  attrs: {
                    "data-source": _vm.dtTable,
                    loading: _vm.loading.table,
                    pagination: {
                      showTotal: function(total) {
                        return _vm.$t("lbl_total_items", { total: total })
                      }
                    },
                    "row-selection": _vm.rowSelection,
                    scroll: { x: "calc(1400px + 50%)", y: 520 }
                  }
                },
                [
                  _c(
                    "a-table-column",
                    { key: "no", attrs: { "data-index": "no", width: 70 } },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_number_short")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "productCode",
                      attrs: { "data-index": "productCode" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " + _vm._s(record.productCode || "-") + " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_product_code")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "productName",
                      attrs: { "data-index": "productName" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " + _vm._s(record.productName || "-") + " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_product_name")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "qty",
                      attrs: { "data-index": "qty", width: 75 },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("toDecimalQty")(record.qty)) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_qty")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "uom",
                      attrs: { "data-index": "uom", width: 100 },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(" " + _vm._s(record.uom || "-") + " ")
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_uom")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "batchNumber",
                      attrs: { "data-index": "batchNumber" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " + _vm._s(record.batchNumber || "-") + " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_batch_number")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "locationName",
                      attrs: { "data-index": "locationName" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm.isModeCreate &&
                              record.status ===
                                _vm.POST_MEATROOM_LINE_STATUS.READY_TO_MEATROOM
                                ? [
                                    _c("CSelectRack", {
                                      model: {
                                        value: record.locationId,
                                        callback: function($$v) {
                                          _vm.$set(record, "locationId", $$v)
                                        },
                                        expression: "record.locationId"
                                      }
                                    })
                                  ]
                                : _c("span", { staticClass: "ant-form-text" }, [
                                    _vm._v(_vm._s(record.locationName || "-"))
                                  ])
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_location")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "condition",
                      attrs: { "data-index": "condition" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm.isModeCreate &&
                              record.status ===
                                _vm.POST_MEATROOM_LINE_STATUS.READY_TO_MEATROOM
                                ? [
                                    _c("CSelectCondition", {
                                      model: {
                                        value: record.condition,
                                        callback: function($$v) {
                                          _vm.$set(record, "condition", $$v)
                                        },
                                        expression: "record.condition"
                                      }
                                    })
                                  ]
                                : _c("span", { staticClass: "ant-form-text" }, [
                                    _vm._v(_vm._s(record.condition || "-"))
                                  ])
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_condition")))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("a-divider"),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { span: 24, align: "end" } },
            [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleBack } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                  ]),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.loading.print,
                        icon: "printer"
                      },
                      on: { click: _vm.handlePrint }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "save",
                        disabled: _vm.disableSubmit,
                        loading: _vm.loading.submit
                      },
                      on: { click: _vm.handleSubmit }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }